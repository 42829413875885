import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { siteRoutes } from '@/misc/routes';

/* Layouts */
const Layout = () => import('../layouts/Layout');
const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout');
const country = process.env.VUE_APP_LOCATION;

Vue.use(VueRouter);

const childRoutes = (prop, mode) => [
  {
    path: '/',
    name: prop + '.list',
    meta: { auth: true, name: 'Social App' },
    component: () => import('../views/Pages/HomeProskers')
  },
  {
    path: 'marketing',
    name: prop + '.marketing-page',
    meta: { auth: true, name: 'Marketing' },
    component: () => import('../views/Pages/Marketing')
  },
  {
    path: siteRoutes['home-proskers'][country],
    name: prop + '.proskers-page',
    meta: { auth: true, name: 'HomeProskers' },
    component: () => import('../views/Pages/HomeProskers')
  },
  {
    path: siteRoutes['home-clients'][country],
    name: prop + '.clients-page',
    meta: { auth: true, name: 'HomeClients' },
    component: () => import('../views/Pages/HomeClients')
  },
  {
    path: siteRoutes['subscription'][country],
    name: prop + '.subscription-page',
    meta: { auth: true, name: 'SubscriptionPage' },
    component: () => import('../views/Pages/SubscriptionPage')
  },
  {
    path: siteRoutes['about'][country],
    name: prop + '.us',
    meta: { auth: true, name: 'Us' },
    component: () => import('../views/Pages/Us')
  },
  {
    path: siteRoutes['sell'][country],
    name: prop + '.how-works',
    meta: { auth: true, name: 'HowWorks' },
    component: () => import('../views/Pages/HowWorksProsker')
  },
  {
    path: siteRoutes['buy'][country],
    name: prop + '.how-works-clients',
    meta: { auth: true, name: 'HowWorksClients' },
    component: () => import('../views/Pages/HowWorksClients')
  },
  {
    path: siteRoutes['my-subscription'][country],
    name: prop + '.user-subscription',
    meta: { auth: true, name: 'UserSubscriptions' },
    component: () => import('../views/Pages/UserSubscriptions'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: siteRoutes['terms'][country],
    name: prop + '.terms-and-conditions',
    meta: { auth: true, name: 'TermsAndConditions' },
    component: () => import('../views/Pages/TermsAndConditions')
  },
  {
    path: siteRoutes['my-address'][country],
    name: prop + '.my-addresses',
    meta: { auth: true, name: 'UserAddresses' },
    component: () => import('../views/Pages/UserAddresses'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: siteRoutes['contact'][country],
    name: prop + '.contact-page',
    meta: { auth: true, name: 'ContactPage' },
    component: () => import('../views/Pages/ContactPage')
  },
  {
    path: siteRoutes['favorites'][country],
    name: prop + '.favorites',
    meta: { auth: true, name: 'UserFavorites' },
    component: () => import('../views/User/UserFavorites'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: siteRoutes['orders'][country],
    name: prop + '.user-orders',
    meta: { auth: true, name: 'UserOrders' },
    component: () => import('../views/User/UserOrders'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: siteRoutes['billing'][country],
    name: prop + '.billing',
    meta: { auth: true, name: 'Billing' },
    component: () => import('../views/Pages/Billing')
  },
  {
    path: siteRoutes['plans'][country],
    name: prop + '.subscription-plans',
    meta: { auth: true, name: 'SubscriptionPlans' },
    component: () => import('../views/Pages/SubscriptionPlans')
  },
  {
    path: siteRoutes['profile'][country],
    name: prop + '.user-profile',
    props: true,
    meta: { auth: true, name: 'UserProfile', bgColor: 'light_grey' },
    component: () => import('../views/User/UserProfile')
  },
  {
    path: siteRoutes['services'][country],
    name: prop + '.services',
    meta: { auth: true, name: 'UserServices' },
    component: () => import('../views/User/UserServices'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: siteRoutes['hired-services'][country],
    name: prop + '.user-services',
    meta: { auth: true, name: 'UserServices' },
    component: () => import('../views/User/UserServices'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: siteRoutes['category'][country],
    name: prop + '.categories-page',
    meta: { auth: true, name: 'Categories' },
    component: () => import('../views/Pages/Categories')
  },
  {
    path: siteRoutes['categories'][country],
    name: prop + '.categories-page',
    meta: { auth: true, name: 'Categories' },
    component: () => import('../views/Pages/Categories')
  },
  {
    path: siteRoutes['profiles'][country],
    name: prop + '.users',
    props: true,
    meta: { auth: true, name: 'User List' },
    component: () => import('../views/User/UserList')
  },
  {
    path: 'admin-servicios',
    name: prop + '.jobs-admin',
    meta: { auth: true, name: 'JobsAdmin' },
    component: () => import('../views/Pages/JobsAdmin'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: 'admin-servicios-detalle/:query',
    name: prop + '.jobs-admin-details',
    meta: { auth: true, name: 'JobsAdminDetails' },
    component: () => import('../views/Pages/JobsAdminDetails'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: 'admin/cupones',
    name: 'admin.coupons',
    meta: { auth: true, name: 'CouponsList' },
    component: () => import('../views/Pages/Admin/Coupons/index'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: 'admin/cupones/:id/edit',
    name: prop + '.coupons.edit-coupon',
    meta: { auth: true, name: 'EditCoupons' },
    component: () => import('../views/Pages/Admin/EditCoupons/index'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: 'admin/users',
    name: prop + '.users-information',
    meta: { auth: true, name: 'UsersList' },
    component: () => import('../views/Pages/Admin/Users/index'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
    },
    {
      path: 'admin/users/:id/edit',
      name: prop + '.users-information.edit-user',
      meta: { auth: true, name: 'EditUser' },
      component: () => import('../views/Pages/Admin/EditUsers/index'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'auth1.sign-in1'
          });
        }
        next();
      }
    }, {
    path: 'admin-especialidades',
    name: prop + '.skills',
    meta: { auth: true, name: 'SkillsList' },
    component: () => import('../views/Pages/SkillsList'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: 'admin-categorias',
    name: prop + '.categories',
    meta: { auth: true, name: 'CategoriesList' },
    component: () => import('../views/Pages/CategoriesList'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  },
  {
    path: 'admin-cancelacion',
    name: prop + '.cancellation-reason',
    meta: { auth: true, name: 'CancellationReason' },
    component: () => import('../views/Pages/CancellationReason'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'auth1.sign-in1'
        });
      }
      next();
    }
  }
];
const appChildRoute = (prop, mode = false) => [
  {
    path: 'agenda',
    name: prop + '.calendar',
    meta: {
      auth: true,
      name: 'Calendar',
      img: require('../assets/images/page-img/100.jpg')
    },
    component: () => import('../views/Apps/Calendar/Calendar')
  },
  {
    path: 'chat/:conversationId',
    name: prop + '.chat',
    props: true,
    meta: { auth: true, name: 'Chat' },
    component: () => import('../views/Apps/Chat/Chat')
  }
];
const authChildRoutes = (prop, mode = false) => [
  {
    path: 'inicio-de-sesion/:query?',
    name: prop + '.sign-in1',
    props: true,
    meta: { auth: true },
    component: () => import('../views/AuthPages/Default/SignIn1')
  },
  {
    path: 'registrarse/:query?',
    name: prop + '.sign-up1',
    props: true,
    meta: { auth: true },
    component: () => import('../views/AuthPages/Default/SignUp1')
  },
  {
    path: siteRoutes['forgot-pass'][country],
    name: prop + '.password-reset',
    meta: { auth: true },
    component: () => import('../views/AuthPages/Default/RecoverPassword')
  },
  {
    path: '/nueva-contrasena/:token',
    name: 'password-reset',
    props: true,
    component: () => import('../views/AuthPages/Default/ResetPassword')
  },
  {
    path: 'confirmacion/:email',
    props: true,
    name: prop + '.confirm-mail1',
    meta: { auth: true },
    component: () => import('../views/AuthPages/Default/ConfirmMail1')
  }
];
const routes = [
  {
    path: '/page-not-found',
    component: () => import('../views/Error/404Page')
  },
  {
    path: '*',
    redirect: '/page-not-found'
  },
  {
    path: '/',
    name: 'social',
    component: Layout,
    children: childRoutes('social')
  },
  {
    path: '/login/facebook/callback',
    name: 'callback',
    component: () => import('../views/AuthPages/Default/CallbackFacebook')
  },
  {
    path: '/login/google/callback',
    name: 'callback',
    component: () => import('../views/AuthPages/Default/CallbackGoogle')
  },
  {
    path: '/user/mercado-pago/callback',
    name: 'callbackMP',
    component: () => import('../views/AuthPages/Default/CallbackMercadoPago')
  },
  {
    path: siteRoutes['checkout-service'][country],
    name: 'service-checkout',
    props: true,
    component: () => import('../views/AuthPages/Default/Checkout')
  },
  {
    path: siteRoutes['checkout-subscription'][country],
    name: 'subscription-checkout',
    props: true,
    component: () => import('../views/AuthPages/Default/Checkout')
  },
  {
    path: '/user/verification/:token',
    name: 'verification-email',
    props: true,
    component: () => import('../views/AuthPages/Default/VerificationEmail')
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  },
  {
    path: '/app',
    name: 'app',
    component: Layout,
    meta: { auth: true },
    children: appChildRoute('app')
  }
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    }
    return savedPosition || { x: 0, y: 0 };
  }
});
export default router;
